<template>
    <div>
        <div class="shuno-row" :class="{expanded: expanded}" @click="expanded = !expanded">
            <div class="color" :class="dev.state === 'Online' ? 'online' : 'offline'"
                 style="border-radius: 0"></div>
            <div class="title">{{ devParent.serial }} - {{ dev.serial }}</div>

            <div class="ico-state" style="border: none">
                <img v-if="doorStatus" src="/images/icons/dev-state/door.svg">
                <img v-else src="/images/icons/dev-state/door-open.svg">
            </div>

            <div class="ico-state">
                <img src="/images/icons/dev-state/temperature.svg">
                —°С
            </div>

            <div class="ico-state">
                <img src="/images/icons/dev-state/humidity.svg">
                —%
            </div>

            <div class="ico-state">
                <img src="/images/icons/dev-state/lux.svg">
                —lux
            </div>

            <div class="ico-state">
                A
                <div class="status-ico" :class="{'on': lineStatus('A')}"></div>
            </div>

            <div class="ico-state">
                B
                <div class="status-ico" :class="{'on': lineStatus('B')}"></div>
            </div>

            <div class="ico-state">
                C
                <div class="status-ico" :class="{'on': lineStatus('C')}"></div>
            </div>

            <div class="ico-state" style="display: none">
                <img @click="rebootDev()" style="height: 20px;" src="/images/icons/reload.svg">
            </div>

            <div class="arrow"><img src="/images/icons/arrow-down.svg"></div>
        </div>

        <div class="p-lines" v-if="expanded" style="margin-bottom: 15px">
            <div class="shuno-row line">
                <div style="width: 232px; margin-left: 35px">Расписание</div>
                <div style="width: 200px">
                    <pe-select :value="dev.scheduleId"
                               @input="setSchedule($event)"
                               :options="schedules"
                               class="h32"
                               style="margin: 0"
                               placeholder="Выбор расписания"></pe-select>
                </div>
            </div>

            <div class="shuno-row line" v-for="(outNum, i) in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]">
                <div class="title">Линия {{ outNum }}</div>

                <div class="power-switch">
                    <div class="sw" :class="{active: getReleState(i) === true}" @click="toggleRele(i, true)">Вкл
                    </div>
                    <div class="sw" :class="{active: getReleState(i) === false}" @click="toggleRele(i, false)">
                        Выкл
                    </div>
                </div>

                <div style="width: 200px; margin-left: 20px;">
                    <pe-select :value="dev.releGroupSettings['group_id_out_'+i]"
                               @input="setGroup($event, i)" class="h32"
                               :options="groups"
                               style="margin: 0"
                               placeholder="Выбор группы"></pe-select>
                </div>
            </div>

        </div>

    </div>
</template>


<script>
import myBlock from '../../../../components/responsiveBlock';
import {DEVICE_UPDATE_PROP} from "../../../../store/mutations";
import devsApi from "../../../../api/devices";
import {each, filter, find} from "lodash";
import peSelect from "../../../../components/peSelect";
import devicesApi from "../../../../api/devices";
import {devTypes} from "../../../../config";
import {mapActions} from 'vuex'

// статусы фаз и двери
const outStatusNum = {
    door: 7,
    A: 8,
    B: 9,
    C: 10,
};

export default {

    props: {
        dev: Object,
    },

    components: {
        myBlock,
        peSelect,
    },

    data() {
        return {
            expanded: false,
            updateTimeout: false,
        }
    },

    computed: {
        schedules() {
            let options = {
                0: 'Без расписания'
            };
            each(this.$store.state.schedules.all, (s) => {
                options[s.id] = s.title;
            })
            return options;
        },

        groups(){
            let data = {
                0: 'Без группы'
            };
            let groups = filter(this.$store.state.groups.all,{ groupType:  devTypes.shuno})
            each(groups, (s) => {
                data[s.id] = s.name;
            })
            return data;
        },

        _getDI(){
            return filter(this.$store.state.devices.all, {
                nodeSerial: this.dev.nodeSerial,
                data: {
                    deviceType: "DI16"
                }
            })[0]
        },

        doorStatus(){
            if(this._getDI === undefined) return false;
            return this._getDI.data.digitalEndpoints[outStatusNum.door].state.value;
        },

        devParent(){
            return find(this.$store.state.devices.all, { serial: this.dev.nodeSerial });
        }
    },

    methods: {
        lineStatus(phase){
            if(this._getDI === undefined) return false;
            // console.log(JSON.stringify(this._getDI, null, 4))
            return this._getDI.data.digitalEndpoints[outStatusNum[phase]].state.value;
        },

        setSchedule(scheduleId) {
            scheduleId = parseInt(scheduleId)
            this.$store.commit(DEVICE_UPDATE_PROP, {
                id: this.dev.id,
                prop: scheduleId,
                val: scheduleId
            })

            devsApi.setSchedule(this.dev.id, scheduleId);
        },

        setGroup(groupId, releNum) {
            groupId = parseInt(groupId)
            let groups = JSON.parse(JSON.stringify(this.dev.releGroupSettings));
            groups['group_id_out_'+releNum] = groupId;
            this.$store.commit(DEVICE_UPDATE_PROP, {
                id: this.dev.id,
                prop: 'releGroupSettings',
                val: groups
            })

            devsApi.update(this.dev.id, {
                id: this.dev.id,
                releGroupSettings: groups,
            })
        },

        toggleRele(outNum, onOff) {
            devicesApi.ctrl(this.dev.id, 'out/' + outNum, !!onOff);
            if (this.dev && this.dev.data && this.dev.data.digitalEndpoints) {
                this.deviceShunoReleStatusSet({
                    id: this.dev.id,
                    outNum,
                    val: onOff,
                })
                // this.dev.data.digitalEndpoints[outNum].state.value = onOff;
                // this.$forceUpdate();
            }
            if(this.updateTimeout) clearTimeout(this.updateTimeout);
            this.updateTimeout = setTimeout(() => {
                this.$store.dispatch('devicesLoad', this.$route.params.projectId)
            }, 2000)
        },

        rebootDev() {
            devicesApi.ctrl(this.dev.id, 'control/reboot', true);
        },

        getReleState(releNum) {
            if (this.dev && this.dev.data && this.dev.data.digitalEndpoints &&
                this.dev.data.digitalEndpoints[releNum] && this.dev.data.digitalEndpoints[releNum].state) {
                return this.dev.data.digitalEndpoints[releNum].state.value;
            }
            return undefined;
        },

        ...mapActions([
            'deviceShunoReleStatusSet'
        ]),
    }
}
</script>