<template>
    <div class="center-content project-add-edit">

        <div class="block">
            <img class="closer" @click="$to('projects')" src="/images/icons/close.svg">

            <div class="h3" style="padding-bottom: 15px">
                <span v-if="state === 'add'">Добавление проекта</span>
                <span v-else>Редактирование проекта</span>
            </div>

            <form @submit.prevent="sendData">

                <pe-input v-model="form.data.name" :error="form.errors.name"
                          type="text" placeholder="Название проекта" autocomplete="off"></pe-input>

                <div class="flex">
                    <pe-select v-model="form.data.country" :error="form.errors.country"
                               :options="countryList"
                               style="margin-right: 30px"
                               placeholder="Страна"></pe-select>

                    <pe-input-autocomplete v-model="form.data.city"
                                           placeholder="Город" autocomplete="off"
                                           :error="form.errors.city"
                                           @change="loadCityList"
                                           :variants="cityVariants"
                                           @selected="citySelected"
                                           @keydown.esc="citySelected"
                    ></pe-input-autocomplete>
                </div>

                <div class="flex">
                    <pe-input-autocomplete v-model="form.data.address"
                                           style="width: 300px; margin-right: 30px"
                                           placeholder="Адрес" autocomplete="off"
                                           :error="form.errors.address"
                                           @change="loadFromDadata"
                                           :variants="daData.address.variants"
                                           @selected="addressSelected"
                                           @keydown.esc="addressSelected"
                    ></pe-input-autocomplete>

                    <pe-select class="pe-select" v-model="form.data.timeZoneId" :options="timezones"
                               :error="form.errors.timeZoneId"
                               style="width: 150px"
                        placeholder="Часовой пояс"></pe-select>
                </div>

                <!--<template v-if="state === 'edit'">
                    <div class="h3" style="margin: 25px 0 15px">Внесите список ID устройств</div>

                    <pe-input v-model="form.data.devList" :error="form.errors.devList"
                              type="text" placeholder="Напишите ID одного или нескольких устройств через пробел" autocomplete="off"></pe-input>
                </template>-->

                <div class="flex" style="margin-top: 40px">
                    <button v-if="state === 'add'" class="btn red" type="button" style="margin-right: 60px"
                            @click="$to('projects')">Отмена</button>

                    <button v-else class="btn red" type="button" style="margin-right: 60px"
                            @click="delDialog()">Удалить</button>

                    <button class="btn green">Сохранить</button>
                </div>
            </form>
        </div>

        <div class="popup-bg" v-if="popups.del">
            <div class="block" style="width: 345px">
                <div class="title">Вы точно хотите удалить этот проект ?</div>
                <div class="btns">
                    <button class="btn red" type="button" @click="delProject()">Удалить</button>
                    <button class="btn green" @click="popups.del = false">Отмена</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import peInput from "../../components/peInput";
    import peInputAutocomplete from "../../components/peInputAutocomplete";
    import peSelect from "../../components/peSelect";
    import { utcList } from "../../data";
    import projectsApi from '../../api/projects'
    import devicesApi from '../../api/devices'
    import axios from 'axios'
    import cities from "../../cities";
    import _ from 'lodash'
    import { settings } from "../../config";
    import {mapState} from "vuex";
    import {devStates, devTypes} from "../../config";

    export default {
        components:{
            peInput,
            peSelect,
            peInputAutocomplete,
        },

        data(){
            return {
                state: 'add',
                utcList,
                countryList: [
                    'Россия',
                    'Белоруссия',
                    'Украина',
                    'другая...',
                ],
                form: {
                    data: {
                        id: 0,
                        name: '',
                        country: 'Россия',
                        city: '',
                        address: '',
                        timeZoneId: settings.defaultTimezone,
                        devList: '',
                        latitude: 0,
                        longitude: 0,
                    },
                    errors: {
                        projectName: '',
                        country: '',
                        city: '',
                        address: '',
                        timeZoneId: '',
                        devList: '',
                    },
                },
                daData:{
                    address:{
                        timer: false,
                        variants: [],
                        skipNextChange: false,
                    },
                    city:{
                        timer: false,
                        variants: [],
                        skipNextChange: false,
                    },
                },
                cityVariants: [],
                popups:{
                    del: false,
                }
            }
        },

        computed: {
            ...mapState({
                dataLoaded: state => state.dataLoaded,
                timezones: state => state.projects.timezones,
            })
        },

        watch:{
            dataLoaded(newVal, oldVal){
                if(newVal && this.state === 'edit'){
                    this.loadDataForForm()
                }
            }
        },

        mounted() {
            this.state = this.$route.name === 'projects-edit' ? 'edit' : 'add';
            if(this.dataLoaded && this.state === 'edit'){
                this.loadDataForForm()
            } else if(this.state === 'add'){
                this.clearForm()
            }
        },

        methods: {
            loadDataForForm(){
                let p = this.$store.state.projects.all[this.$route.params.projectId];
                ['id', 'name', 'country', 'city', 'address',
                    'timeZoneId', 'owner', 'latitude', 'longitude'].forEach((f) =>{
                    this.form.data[f] = p[f]
                })
            },
            clearForm(){
                this.form.data = {
                    id: 0,
                    name: '',
                    country: 'Россия',
                    city: '',
                    address: '',
                    timeZoneId: settings.defaultTimezone,
                    devList: '',
                    latitude: 0,
                    longitude: 0,
                }
            },

            delDialog(){
                this.popups.del = true;
            },
            async delProject(){
                await projectsApi.delete(this.form.data.id)
                await this.$store.dispatch('projectsLoad')
                this.$to('projects')
            },

            async sendData() {
                let hasErrors = false;
                ['name',
                'country',
                'city',
                'timeZoneId',
                ].forEach((v) => {
                    if(!this.form.data[v].length){
                        this.form.errors[v] = 'Обязательное поле'
                        hasErrors = true
                    } else{
                        this.form.errors[v] = ''
                    }
                })
                if(!hasErrors){
                    let data = {
                        id:  this.form.data.id,
                        name: this.form.data.name,
                        country: this.form.data.country,
                        city: this.form.data.city,
                        address: this.form.data.address,
                        timeZoneId: this.form.data.timeZoneId,
                        latitude: this.form.data.latitude,
                        longitude: this.form.data.longitude,
                    }
                    if(this.state === 'add'){
                        await projectsApi.create(data)
                        this.$to('projects')
                    } else {
                        await projectsApi.update(data.id, data)
                    }
                    await this.$store.dispatch('projectsLoad')
                    this.$to('projects')
                }
            },

            loadFromDadata(str){
                if(this.daData.address.skipNextChange){
                    this.daData.address.skipNextChange = false
                    return
                }

                if(this.daData.address.timer) clearTimeout(this.daData.address.timer)

                this.daData.address.timer = setTimeout(()=>{
                    axios.post('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
                        { query: this.form.data.city + ' ' + str, "count": 5 }, {
                        headers:{
                            Authorization: "Token " + settings.dadataApiKey,
                        }
                    }).then((resp)=>{
                        let vars = [];
                        if(resp.data && resp.data.suggestions){
                            if(resp.data.suggestions.length){
                                resp.data.suggestions.forEach((f) => {
                                    // vars.push(f.data.street_with_type)
                                    vars.push(f.value.replace(/.+?, */, ''))
                                })
                                this.daData.address.variants = vars
                            } else {
                                this.daData.address.variants = []
                            }
                        }
                    })
                }, 600)
            },

            addressSelected(){
                this.daData.address.variants = false
                if(this.daData.address.timer) clearTimeout(this.daData.address.timer)
                this.daData.address.skipNextChange = true

                axios.post('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
                    {query: this.form.data.city + ' ' + this.form.data.address, "count": 1}, {
                        headers: {
                            Authorization: "Token " + settings.dadataApiKey,
                        }
                }).then((resp) => {
                    if (resp.data && resp.data.suggestions) {
                        if (resp.data.suggestions.length) {
                            resp.data.suggestions.forEach((f) => {
                                this.form.data.latitude = parseFloat(f.data.geo_lat);
                                this.form.data.longitude = parseFloat(f.data.geo_lon);
                            })
                        }
                    }
                })
            },
            
            
            citySelected(){
                /*this.daData.city.variants = false
                if(this.daData.city.timer) clearTimeout(this.daData.city.timer)
                this.daData.city.skipNextChange = true*/
                this.cityVariants = []
            },

            loadCityList(str){
                if(str.length > 2){
                    let arr = [];
                    _.forEach(cities, (el)=>{
                        if(el.city.toLowerCase().includes(str.toLowerCase())){
                            arr.push(el.city)
                        }
                    })
                    this.cityVariants = arr;
                }
                /*
                if(this.daData.city.skipNextChange){
                    this.daData.city.skipNextChange = false
                    return
                }

                if(this.daData.city.timer) clearTimeout(this.daData.city.timer)

                this.daData.city.timer = setTimeout(()=>{
                    axios.post('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
                        { query: str, "count": 6 }, {
                            headers:{
                                Authorization: "Token " + settings.dadataApiKey,
                            }
                        }).then((resp)=>{
                        let vars = [];
                        if(resp.data && resp.data.suggestions){
                            if(resp.data.suggestions.length){
                                resp.data.suggestions.forEach((f) => {
                                    // vars.push(f.data.street_with_type)
                                    vars.push(f.value)
                                })
                                this.daData.city.variants = vars
                            } else {
                                this.daData.city.variants = []
                            }
                        }
                    })
                }, 600)*/
            },

        }
    }
</script>