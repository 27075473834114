<template>
    <tr>
        <td :style="`width: ${tblWidth.id}px`">
            <span v-if="!isNode">----</span>
            {{ dev.serial }}
        </td>
        <td :style="`width: ${tblWidth.state}px`">
            <div :class="onlineClass"></div>
        </td>
        <td :style="`width: ${tblWidth.errors}px`">Нет</td>
        <td :style="`width: ${tblWidth.type}px`">
            <span v-if="isNode">Нода</span>
            <span v-else>{{dev.data && dev.data.deviceType}}</span>
        </td>
        <td :style="`width: ${tblWidth.fw}px`">{{ dev.data && dev.data.firmwareVersion }}</td>
        <td>
            <template v-if="isNode">
                {{ dev.latitude && dev.latitude.toFixed(5) }},
                {{ dev.longitude && dev.longitude.toFixed(5) }}
            </template>
        </td>
    </tr>
</template>

<script>
export default {
    props:{
        tblWidth: Object,
        dev: Object,
        isNode: Boolean,
    },
    computed: {
        onlineClass(){
            return this.dev.state.toLowerCase() === 'online' ? 'online' : 'offline';
        }
    }
}
</script>