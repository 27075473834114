<template>
    <div class="pe-input" :class="{'error': error, 'success': success}">
        <input :type="fType"
               :value="value"
               :tabindex="tabIndex"
               @input="change($event)"
               @keyup="$emit('keyup', $event)"
               @keydown="$emit('keydown', $event)"
               @focus="$emit('focus', $event)"
               @blur="$emit('blur', $event)"
               :min="min"
               :max="max"
               :placeholder="placeholder">
        <div class="show-pwd" v-if="type === 'password'">
            <img v-if="showPwd" @click="showPwd = !showPwd" src="/images/icons/eye-cross.svg">
            <img v-else @click="showPwd = !showPwd" src="/images/icons/eye.svg">
        </div>
        <div class="ico-success"><img src="/images/icons/ok.svg"></div>
        <div class="ico-error"><img src="/images/icons/cross.svg"></div>
        <div class="err-hint" v-if="error">{{error}}</div>
    </div>
</template>


<script>
    export default {
        props: ['value', 'type', 'placeholder', 'error', 'success', 'tabIndex', 'min', 'max'],

        data() {
            return {
                showPwd: false,
            }
        },

        computed: {
            fType(newVal, oldVal) {
                if (this.type === 'password') {
                    return this.showPwd ? 'text' : 'password'
                } else {
                    return this.type
                }
            }
        },

        methods: {
            change(e) {
                this.$emit('input', e.target.value)
                this.$emit('change', e.target.value)
            },
        }
    }
</script>
