import axios from 'axios'

import base from './_base'

let api = Object.assign({}, base)

api.resource = 'devices'

api.loadByProject = async (projectId, pageSize = 1000, pageIndex = 0) => {
    let response = await axios.get(`${api.baseUrl}devices/project/` + projectId,{
        params:{
            pageSize,
            pageIndex,
        }
    })
    return response.data.data;
}

api.addToProject = async (projectId, data) => {
    let response = await axios.post(`${api.baseUrl}devices/` + projectId, data)
    return response.data;
}

api.updateCoords = async (id, lat, lng, onError) => {
    try {
        let data = {
            id,
            latitude: parseFloat(lat),
            longitude: parseFloat(lng),
        }
        return await api.update(id, data, onError)
    } catch (e) {
        console.error(e)
        if(onError) onError(e)
        return false
    }
}

api.setSchedule = async (devId, scheduleId) => {
    if(scheduleId){
        let data = {
            id: devId,
            scheduleId,
        };
        let response = await axios.put(`${api.baseUrl}devices/${devId}/schedule`, data)
        return response.data;
    } else {
        await axios.delete(`${api.baseUrl}devices/${devId}/schedule`);
        return true;
    }
}

api.ctrl = (devId, metric, val) => {
    let cmd = {
        metricName: metric,
        // boolValue: false,
        // uintValue: 0
    }
    if(typeof val === 'boolean'){
        cmd.boolValue = val
    } else {
        cmd.uintValue = parseInt(val)
    }
    axios.post('/api/command/device/' + devId, {
        deviceId: devId,
        commands: [ cmd ]
    })
}

api.ctrlString = (devId, metric, val) => {
    let cmd = {
        metricName: metric,
        stringValue: val,
    }
    axios.post('/api/command/device/' + devId, {
        deviceId: devId,
        commands: [ cmd ]
    })
}

/*
metrics: [{
    metric: 'out/1',
    val: false,
}, ...]
 */
api.ctrlMulti = (devId, metrics) => {
    let commands = [];
    metrics.forEach((m) => {
        let cmd = {
            metricName: m.metric,
            // boolValue: false,
            // uintValue: 0
        }
        if(typeof m.val === 'boolean'){
            cmd.boolValue = m.val
        } else {
            cmd.uintValue = parseInt(m.val)
        }
        commands.push(cmd);
    })

    axios.post('/api/command/device/' + devId, {
        deviceId: devId,
        commands: commands
    })
}


export default api